import { ApexOptions } from 'apexcharts';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card, { CardProps } from '@mui/material/Card';
// import { useTranslate } from '../../../../locales';
import { useResponsive } from '../../../../hooks/use-responsive';
import Chart, { useChart } from '../../../../components/minimal/chart';
import { CardHeader } from '@mui/material';


// ----------------------------------------------------------------------

interface Props extends CardProps {
  chart: {
    colors?: string[][];
    series: {
      label: string;
      percent: number;
      title: string;
    }[];
    options?: ApexOptions;
  };
}

export default function AgeRanges({ statistics }: { statistics: any }) {
  // { chart, ...other }: Props
  const theme = useTheme();
  // const { t } = useTranslate();
  const smUp = useResponsive('up', 'sm');

  const data = statistics?.studentsByAgeRange?.map(
    (ages: { range: number; proportion: number }) => ({
      title: ages?.range,
      percent: ages?.proportion,
      // label: t('common.year'),
      label: 'Year',
    })
  ) || [];
  const chart = {
    series: data,
    // series: [
    //   { label: 'Year', percent: 20, title: 'Under 10' },
    //   { label: 'Year', percent: 40, title: `10:16` },
    //   { label: 'Year', percent: 30, title: `16:24` },
    //   { label: 'Year', percent: 10, title: 'Above 30' },
    // ],
  };

  const {
    // colors = [
    //   [theme.palette.primary.light, theme.palette.primary.main],
    //   [theme.palette.warning.light, theme.palette.warning.main],
    // ],
    series,
    // options,
  } = chart;

  const chartOptionsCheckIn = useChart({
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: '#FFD073', opacity: 1 },
          // { offset: 0, color: '#FF9AA7', opacity: 1 },
          //   { offset: 100, color: 'green', opacity: 1 },
          //   { offset: 100, color: 'yallow', opacity: 1 },
        ],
      },
    },
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    grid: {
      padding: {
        top: -9,
        bottom: -9,
      },
    },
    legend: {
      show: false,
    },
    // tooltip: {
    //   enabled: true,
    //   y: {
    //     formatter: (_, { seriesIndex, w }) => {
    //       const dataPoint = w.config.series[seriesIndex];
    //       return `${dataPoint.count} Children`;
    //     },
    //   },
    // },
    plotOptions: {
      radialBar: {
        hollow: { size: '64%' },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize as string,
          },
        },
      },
    },
    // ...options,
  });

  const chartOptionsCheckout = {
    ...chartOptionsCheckIn,
    fill: {
      type: 'gradient',
      gradient: {
        colorStops: [
          { offset: 0, color: '#005639', opacity: 1 },
          //   { offset: 100, color: colors[1][1], opacity: 1 },
        ],
      },
    },
  };

  return (
    <Card sx={{px: 3, py: 2}}>
      <CardHeader
        // title={t('statistics.students.ageOfStudents')}
        title={'Age of Children'}
        subheader={
          // statistics?.childCounts ?
          <Typography sx={{ opacity: 0.72 }} mt={1}>
            {/* {statistics?.childCounts} {t('students.title')} */}
            {statistics?.childCounts} {'Children'}
          </Typography> 
          // : null
        }
        sx={{ p: '0!important'}}
      />
      {series?.length > 0 ? (
        <Stack direction={{ xs: 'column', sm: 'row' }}>
          {series?.map(
            (
              item: {
                percent: number;
                label: string;
                title: string;
              },
              index: number
            ) => (
              <Stack
                key={item.label}
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent={{ sm: 'center' }}
                sx={{
                  py: 2,
                  width: 1,
                  px: { xs: 3, sm: 0 },
                }}
              >
                <Chart
                  dir="ltr"
                  type="radialBar"
                  series={[item.percent]}
                  options={index === 1 || index === 3 ? chartOptionsCheckout : chartOptionsCheckIn}
                  width={106}
                  height={106}
                />

                <div>
                  <Typography variant="h5" sx={{ mb: 0.5 }}>
                    {item.title}
                  </Typography>

                  <Typography variant="body2" sx={{ opacity: 0.72 }}>
                    {item.label}
                  </Typography>
                </div>
            </Stack>
          ))}
        </Stack>
      ) : (
        <Typography variant="body2" sx={{ opacity: 0.72, textAlign: 'center', mt: 2 }}>
          No data to display.
        </Typography>
      )}
    </Card>
  );
}
