// Layout.tsx
import React, { ReactNode, useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
import Header from './components/header';
import Navigation from './components/navigation/navigation';
import SnackbarProvider from '../components/minimal/snackbar/snackbar-provider';
import ThemeProvider from '../theme';
// import { LocalizationProvider } from '../locales';
import { SettingsProvider } from '../components/minimal/settings';

interface LayoutProps {
  children?: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawerWidth = 240; // Define the drawer width

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header handleDrawerOpen={handleDrawerOpen} open={open} />
      <Navigation handleDrawerClose={handleDrawerClose} open={open} />
      <Box 
        component="main" 
        sx={{ 
          flexGrow: 1,
          pt: 10,
          px: 3,
          width: `calc(100% - ${open ? drawerWidth : 0}px)`,
          overflowY: 'hidden'
        }} 
        className="min-h-screen bg-gray-100">
        {/* <LocalizationProvider> */}
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light', // 'light' | 'dark'
              themeDirection: 'ltr', //  'rtl' | 'ltr'
              themeContrast: 'default', // 'default' | 'bold'
              themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
              themeColorPresets: 'orange', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
              themeStretch: false,
            }}>
              <ThemeProvider>
                <SnackbarProvider>
                  {/* Your main content goes here */}
                  {children}
                </SnackbarProvider>
              </ThemeProvider>
          </SettingsProvider>
        {/* </LocalizationProvider> */}
      </Box>
    </Box>
  );
};

export default Layout;