// DataTableRow.tsx
import React, { useState } from "react";

import {
  TableRow,
  TableCell,
  Checkbox,
  Avatar,
  ListItemText,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";

import Iconify from "../../minimal/iconify";
import Label from "../../minimal/label";
import Image from "../../minimal/image";
import { ConfirmDialog } from "../../minimal/custom-dialog";

import { ASSETS_API, ASSETS_API_PREFIX } from "../../../config-global";
import { useBoolean } from "../../../hooks/use-boolean";

interface DataTableRowProps {
  row: any;
  selected: boolean;
  onSelectRow: () => void;
  onDeleteRow: () => void;
  onEditRow?: () => void;
  onViewRow?: () => void;
  onListCellClick: (state: boolean) => void;
  onListOptionClick: (approval: string) => void;
  columns: any[];
  deleteTitle: string;
  mainCol: string;
  selectionMode: "single" | "multiple"; // Add prop for selection mode
  hasSelections: boolean;
  hasEdit: boolean;
  hasDelete: boolean;
  hasView: boolean;
  hasOptions: boolean;
}

const DataTableRow: React.FC<DataTableRowProps> = ({
  row,
  selected,
  onSelectRow,
  onDeleteRow,
  onEditRow,
  onViewRow,
  onListCellClick,
  columns,
  deleteTitle,
  mainCol,
  selectionMode,
  hasSelections,
  hasEdit,
  hasDelete,
  hasView,
  hasOptions
}) => {
  const confirm = useBoolean();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    setIsDeleting(true);
    onDeleteRow();
  };

  const handleDialogClose = () => {
    setIsDeleting(false);
    confirm.onFalse();
  };

  const handleState = (state: boolean) => {
    onListCellClick(state);
  };

  // Modify the row selection handler to consider the selection mode
  const handleSelectRow = () => {
    // if (selectionMode === 'single') {
    // If the selection mode is 'single', clear the selection of all other rows
    // and then toggle the selection of the current row
    // For single selection mode, just toggle selection of the current row
    // onSelectRow();
    // } else {
    // For multiple selection mode, toggle selection without affecting other rows
    // onSelectRow();
    // }
    onSelectRow();
  };

  // Functions to render different types of cells
  const renderCheckbox = () => {
    if (hasSelections) {
      if (selectionMode === "single") {
        // For single selection mode, render a regular checkbox
        return <Checkbox checked={selected} onClick={handleSelectRow} />;
      } else {
        // For multiple selection mode, render a checkbox that toggles selection
        return <Checkbox checked={selected} onChange={handleSelectRow} />;
      }
    } else {
      return null;
    }
  };

  const renderNormalCell = (col: any, row: any) =>
    row[col.id] !== undefined && row[col.id] !== null ? (
      <div>{row[col.id]}</div>
    ) : null;

  const renderAvatarCell = (col: any, row: any) => {
    return row[col.id] !== undefined && row[col.id] !== null ? (
      <div className="flex items-center">
        {row[col?.son] !== undefined && row[col?.son] !== null ? (
          <Avatar
            alt={row[col.id]}
            src={ASSETS_API + ASSETS_API_PREFIX + row[col?.son][col?.child]}
            sx={{ mr: 2 }}
          />
        ) : (
          <Avatar alt={row[col.id]} src={row[col.avatar]} sx={{ mr: 2 }} />
        )}

        <ListItemText
          primary={row[col.id]}
          primaryTypographyProps={{ typography: "body2" }}
        />
      </div>
    ) : null;
  };

  const renderDateCell = (col: any, row: any) =>
    row[col.id] !== undefined && row[col.id] !== null ? (
      <div>
        {new Date(row[col.id]).toLocaleDateString("en-GB")}{" "}
        {/* Assuming each column has a 'field' property */}
      </div>
    ) : null;

  const renderNumberCell = (col: any, row: any) =>
    row[col.id] !== undefined && row[col.id] !== null ? (
      <div>{row[col.id]}</div>
    ) : null;

  const renderImageCell = (col: any, row: any) =>
    row[col.id] !== undefined && row[col.id] !== null ? (
      <div>
        {/* <Avatar alt={row[col.id]} src={ASSETS_API + ASSETS_API_PREFIX + row[col.id]} sx={{ mr: 2 }} /> */}
        {/* <img alt={row[col.id]} src={ASSETS_API + ASSETS_API_PREFIX + row[col.id]} /> */}
        {/* <Image src={ASSETS_API + row[col.id]} />  */}
        <Image
          ratio="1/1"
          alt="gallery"
          src={ASSETS_API + ASSETS_API_PREFIX + row[col.id]}
          sx={{ borderRadius: 100, width: "40px" }}
          effect="opacity"
          // onClick={() => lightbox.onOpen(image.imageUrl)}
          // overlay={`linear-gradient(to bottom, ${alpha(theme.palette.grey[900], 0)} 0%, ${
          //   theme.palette.grey[900]
          // } 75%)`}
        />
      </div>
    ) : (
      <div className="flex items-center">
        {/* <Avatar alt={row[col.id]} src={ASSETS_API + ASSETS_API_PREFIX + row[col.id]} sx={{ mr: 2 }} /> */}
        {/* <img alt={row[col.id]} src={ASSETS_API + ASSETS_API_PREFIX + row[col.id]} /> */}
        {/* <Image src={ASSETS_API + row[col.id]} />  */}
        <Iconify
          icon="solar:link-bold"
          sx={{
            border: "1px solid orange",
            borderRadius: 4,
            padding: "0.5rem",
            width: "fit-content",
            height: "100%",
            color: "#FFFFFF",
            backgroundColor: "orange",
            fontSize: "large",
          }}
        />
        {/* <IconButton sx={{color: "#FF5630"}} onClick={() => {confirm.onTrue();}}>
      <Iconify icon="solar:link-bold" />
    </IconButton> */}
      </div>
    );

  const renderListCell = (col: any, row: any) => {
    // const handleClick = () => {
    //   // Perform API call using Axios
    //   axios.post('your-api-endpoint', { data: row[col.id] })
    //     .then(response => {
    //       // Handle success response
    //       console.log('API call successful:', response.data);
    //     })
    //     .catch(error => {
    //       // Handle error
    //       console.error('Error occurred during API call:', error);
    //     });
    // };
    return row[col.id] !== undefined && row[col.id] !== null ? (
      // return (
      <div>
        <Label
          variant="soft"
          color={
            // (row[col.id] === 'active' && 'success') ||
            // (row[col.id] === 'pending' && 'warning') ||
            // (row[col.id] === 'banned' && 'error') ||
            (row[col.id] === col?.list[0] && "success") ||
            (row[col.id] === col?.list[1] && "error") ||
            "default"
          }
          sx={{ p: 2, borderRadius: 2 }}
          // onClick={handleClick} // Attach click event handler
          // onClick={handleState} // Attach click event handler
          onClick={() => handleState(row[col.id])} // Attach click event handler
          style={{ cursor: col.clickable ? "pointer" : "default" }} // Change cursor to pointer
        >
          {/* {row[col.id]} */}
          {`${row[col.id]}`}
        </Label>
      </div>
    ) : // )
    null;
  };

  const renderObjectCell = (col: any, row: any) => {
    // debugger;
    return row[col.id] !== undefined && row[col.id] !== null ? (
      <div>{row[col.id][col.child]}</div>
    ) : null;
  };

  const renderObjectObjectCell = (col: any, row: any) => {
    // debugger;
    return row[col.id] !== undefined && row[col.id] !== null ? (
      <div>{row[col.id][col.child][col.child2]}</div>
    ) : null;
  };

  const renderObjectListCell = (col: any, row: any) => {
    return row[col.id] !== undefined && row[col.id] !== null ? (
      <div>
        <Label
          variant="soft"
          color={
            (row[col.id][col.child] === col?.list[0] && "success") ||
            (row[col.id][col.child] === col?.list[1] && "warning") ||
            "default"
          }
          sx={{ p: 2, borderRadius: 2 }}
          // style={{cursor: 'pointer'}}
        >
          {row[col.id].value}{" "}
          {/* Assuming the value you want to render is stored in 'value' */}
        </Label>
      </div>
    ) : null;
  };

  const renderActionCell = (confirm: any, onEditRow: any) => {
    return (
      <div className='px-1 flex justify-end'>
        {hasView &&
          <Tooltip title="View" placement="top" arrow>
            <IconButton onClick={onViewRow}>
              <Iconify icon="solar:eye-bold" />
            </IconButton>
          </Tooltip>
        }

        {hasEdit && 
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton onClick={onEditRow}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>
        }

        {hasDelete &&
          <Tooltip title="Delete" placement="top" arrow>
            <IconButton sx={{color: "#FF5630"}} onClick={() => {confirm.onTrue();}}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip>
        }

        {hasOptions &&
          <IconButton>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        }
      </div>
    )
  };
  
  return (
    <>
      <TableRow key={row.id}>
        {/* Render checkbox cell */}
        <TableCell padding="checkbox">{renderCheckbox()}</TableCell>

        {/* Render data cells */}
        {columns.map((col: any) => (
          <TableCell key={col.id} sx={{ whiteSpace: "nowrap" }}>
            {col.id !== "actions"
              ? // Render data cells based on column type
                !col.isObjList &&
                !col.isList &&
                !col.isDate &&
                !col.isNumber &&
                !col.isImage &&
                !col.isObj &&
                !col.isObjObj &&
                !col.isAvatar
                ? renderNormalCell(col, row)
                : col.isList
                ? renderListCell(col, row)
                : col.isObjList
                ? renderObjectListCell(col, row)
                : col.isDate
                ? renderDateCell(col, row)
                : // ) : col.isNumber && null
                col.isNumber
                ? renderNumberCell(col, row)
                : col.isImage
                ? renderImageCell(col, row)
                : col.isObj
                ? renderObjectCell(col, row)
                : col.isObjObj
                ? renderObjectObjectCell(col, row)
                : col.isAvatar
                ? renderAvatarCell(col, row)
                : null
              : // Render action cells
                renderActionCell(confirm, onEditRow)}
          </TableCell>
        ))}
      </TableRow>

      <ConfirmDialog
        open={confirm.value}
        onClose={handleDialogClose}
        title={`Delete ${deleteTitle}`}
        content={`Are you sure want to delete (${row[mainCol]})?`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            disabled={isDeleting}
          >
            Delete
          </Button>
        }
      />
    </>
  );
};

export default DataTableRow;
