export const CHALLENGE_COLUMNS = [
    { id:'coverImageUrl', label: 'Cover', width: '6%', isImage: true },
    { id: 'title', label: 'Name', width: '18%'},
    { id: 'challengeCategory', label: 'Category', width: '18%', isObj: true, child:'title'},
    { id: 'createdAt', label: 'Created Date', width: '12%', isDate: true},
    { id: 'subscriberCount', label:'Subscribers', width: '12%'},
    { id: 'type', label: 'Media Type', width: '12%', isObjList: true, list: ['Image', 'Video'], child: 'label'},
    { id: 'published', label: 'Published', width: '12%', isList: true, list: [true, false], clickable: true},
    { id: 'actions', width: '10%' },
];

export const MEDIA_TYPES_OPTIONS = [
    {id:'IMAGE', value: 'Image'},
    {id:'VIDEO', value: 'Video'}
];