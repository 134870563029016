export const BADGE_COLUMNS = [
    { id:'coverImageUrl', label: 'Cover', width: '6%', isImage: true },
    { id: 'title', label: 'Title', width: '30%'},
    { id: 'type', label: 'Type', width: '17%'},
    { id: 'totalCount', label: 'Count', width: '17%'},
    { id: 'createdAt', label: 'Created Date', width: '20%', isDate: true},
    { id: 'actions', width: '10%' },
];

export const TYPE_OPTIONS = [
    { id: 0, value: 'General' },
    { id: 1, value: 'Daily' },
    { id: 2, value: 'Weekly' },
];

export const DOMAIN_OPTIONS = [
    { id: 0, value: 'Follow Child' },
    { id: 1, value: 'Create Challenge' },
    { id: 2, value: 'Like Challenge' },
    { id: 3, value: 'Comment Challenge' },
    { id: 4, value: 'Do Challenge' },
    { id: 5, value: 'Create Story' },
    { id: 6, value: 'Like Story' },
];

