import * as Yup from 'yup';

export const getBadgeSchema = (isUpdate: boolean) => {
  const coverImageValidation = isUpdate ? Yup.mixed<any>().nullable() : Yup.mixed<any>().nullable().required('Cover Image is required');

  return Yup.object().shape({
    title: Yup.string()
    .required('Title (En) is required')
    .matches(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]+$/, 'Title (En) must contain only English characters'),
    titleAr: Yup.string()
    .required('Title (Ar) is required')
    .matches(/^[\u0600-\u06FF\s0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/, 'Title (Ar) must contain only Arabic characters'),
    coverImage: coverImageValidation,
    type: Yup.number()
      .notOneOf([-1], 'Type is required')
      .required('Type is required'),
    domain: Yup.number()
      .notOneOf([-1], 'Domain is required')
      .required('Domain is required'),
    totalCount: Yup.number()
      .typeError('Total Count must be a number') // when not a number value
      .notOneOf([0], 'Total Count is required') // when 0 value
      .required('Total Count is required'), // when empty
  });
};