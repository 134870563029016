import React from 'react';
import { Grid, SxProps, Theme, Typography } from '@mui/material';
import Iconify from '../../../../components/minimal/iconify';

// Define the props interface
interface StatisticCardProps {
    icon: string; // Icon name for Iconify
    value: number | string; // Value to display (e.g., 33 or "33")
    title: string; // Title to display (e.g., "Students")
    iconSx?: SxProps<Theme>; // Custom styles for the icon
    valueSx?: SxProps<Theme>; // Custom styles for the value
    titleSx?: SxProps<Theme>; // Custom styles for the title
    containerSx?: SxProps<Theme>; // Custom styles for the container
  }

const StatisticCard: React.FC<StatisticCardProps> = ({ 
    icon, 
    value, 
    title, 
    iconSx = {}, 
    valueSx = {}, 
    titleSx = {}, 
    containerSx = {} 
}) => {
  return (
    <Grid
      className="h-full"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        ...containerSx,
      }}
    >
      <Grid item>
        <Iconify sx={{ width: '64px', height: '64px', color: 'primary.main', ...iconSx }} icon={icon} />
      </Grid>
      <Grid item className='pl-5'>
        <Typography fontSize="32px" fontWeight={700} sx={valueSx}>
          {value}
        </Typography>
        <Typography variant="h5" sx={titleSx}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StatisticCard;
