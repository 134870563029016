import * as Yup from 'yup';

export const getChallengeSchema = (isUpdate: boolean) => {
  const coverImageValidation = isUpdate ? Yup.mixed<any>().nullable() : Yup.mixed<any>().nullable().required('Cover Image is required');
  const mediaValidation = isUpdate ? Yup.mixed<any>().nullable() : Yup.mixed<any>().nullable().required('Media is required');
  const startDateValidation = Yup.mixed<any>()
  .nullable()
  .required('Start date is required')
  .test(
    'start-date',
    'Start date must not be less than today',
    function (value) {
      // Check if value is not provided or it's greater than or equal to today
      if (!value || value.getTime() >= new Date().getTime()) {
        return true;
      }
      // Check if the value is within the last 24 hours
      const twentyFourHoursAgo = new Date();
      twentyFourHoursAgo.setDate(twentyFourHoursAgo.getDate() - 1);
      return value.getTime() >= twentyFourHoursAgo.getTime();
    }
  );

const endDateValidation = isUpdate
  ? Yup.mixed<any>().test(
      'date-min',
      'End date must be later than start date',
      function (value, { parent }) {
        // Check if start date is provided before comparing with end date
        if (parent.startDate && value) {
          return value.getTime() > parent.startDate.getTime();
        }
        return true; // Return true if start date or end date is not provided
      }
    )
  : Yup.mixed<any>()
      .required('End date is required')
      .test(
        'date-min',
        'End date must be later than start date',
        function (value, { parent }) {
          // Check if start date is provided before comparing with end date
          if (parent.startDate && value) {
            return value.getTime() > parent.startDate.getTime();
          }
          return true; // Return true if start date or end date is not provided
        }
      );

    const mediaTypeValidation = Yup.string()

  return Yup.object().shape({
    title: Yup.string()
    .required('Title (En) is required')
    .matches(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]+$/, 'Title (En) must contain only English characters'),
    titleAr: Yup.string()
      .required('Title (Ar) is required')
      .matches(/^[\u0600-\u06FF\s0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/, 'Title (Ar) must contain only Arabic characters'),
    description: Yup.string()
    .required('Description (En) is required')
    .matches(/^[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~\s]+$/, 'Description (En) must contain only English characters'),
    descriptionAr: Yup.string()
    .required('Description (Ar) is required')
    .matches(/^[\u0600-\u06FF\s0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/, 'Description (Ar) must contain only Arabic characters'),
    coverImage: coverImageValidation,
    startDate: startDateValidation,
    endDate: endDateValidation,
    challengeCategory: Yup.number().required('Challenge Category is required').positive('Please select challenge category'),
    type: mediaTypeValidation,
    media: mediaValidation,
    published: Yup.boolean(),
  });
};