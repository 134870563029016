import React from 'react';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';

import { Card } from '@mui/material';

import StepForm from './components/step-form';

import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';

// ----------------------------------------------------------------------

interface CreateStepProps {
}

// ----------------------------------------------------------------------

const CreateStep: React.FC<CreateStepProps> = () => {

  const navigate = useNavigate();

  const handleCloseForm = () =>{
    navigate(routes.steps);
  }

    return(
        <div>
            {/* Bread Crumb */}
            <div className='flex items-center justify-between pb-3 h-12'>
              <CustomBreadcrumbs
                links={[
                  { name: 'Management', href: routes.steps },
                  { name: 'Step', href: routes.steps },
                  { name: 'Create' },
                ]}
              />
            </div>
            {/* Step Form */}
            <div className='flex flex-row min-h-96'>
                <h2 className='uppercase font-bold w-1/2'>Info</h2>
                <Card className='w-1/2 p-5'>
                  <StepForm onClose={handleCloseForm} />
                </Card>
            </div>
        </div>
    )
};

export default CreateStep;