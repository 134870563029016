import React, { useCallback, useState, useEffect } from 'react';

import isEqual from 'lodash/isEqual';

import RoundedButton from '../../../components/common/button/rounded-button';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';
import { 
  useTable,  
  emptyRows,
  getComparator,
} from '../../../components/minimal/table';

import { IParentTableFilters, IParentTableFilterValue } from '../../../types/parent';
import {PARENT_COLUMNS} from '../../../consts/parent';

import DataTable from '../../../components/common/data-table/data-table';

import { applyFilter } from '../../../utils/data-table-filter';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { HOST_API } from '../../../config-global';
import { handleErrors } from '../../../handlers/axiosErrorsHandler';
import { getStorage } from '../../../hooks/use-local-storage';
import { routes } from '../../../routes/routes';

import { useAuth } from '../../../hooks/useAuth';

import { useSnackbar } from '../../../components/minimal/snackbar';

// ----------------------------------------------------------------------

interface ParentProps {
}

// ----------------------------------------------------------------------

const defaultFilters: IParentTableFilters = {
  username: '',
};

// ----------------------------------------------------------------------

const Parent: React.FC<ParentProps> = () => {
  const { setAuthenticated } = useAuth();

  const token = getStorage('Token');

  const { enqueueSnackbar } = useSnackbar();

  const table = useTable();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);

  const [filters, setFilters] = useState(defaultFilters);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get(`${HOST_API}/api/admin/users/parents`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-locale': 'en',
          }
        }).then(res =>{
          var response = res.data;
          setTableData(response.data.items);
        })
      } catch (error: any) {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      }
    };

    fetchData();
  }, [token, setAuthenticated, enqueueSnackbar]);

  const denseHeight = table.dense ? 52 : 72;

  const resetFilters = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && resetFilters) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IParentTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleAddRow = () =>{
    navigate(routes.parentsCreate);
  }

  const handleEditRow = useCallback(
    (id: string) => {
      navigate(routes.parentsEdit(id));
    },
    [navigate]
  );

  const handleDeleteRow = useCallback(
    async (id: string) => {
      await axios.delete(`${HOST_API}/api/admin/users/parents/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-locale': 'en',
        }
      })
      .then(res => {
        if (res.status === 200) {
          // If successful, update the table data state
          const deleteRow = tableData.filter((row:any) => row.id !== id);
          setTableData(deleteRow);

          // Update any other relevant state or perform actions
          table.onUpdatePageDeleteRow(dataInPage.length);

          enqueueSnackbar('Delete success!', {
            // variant: 'success',
            // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
          });
        } else {
          console.error('Delete request failed');
        }
      })
      .catch(error => {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      });
    },
    [dataInPage.length, table, tableData, token, setAuthenticated, enqueueSnackbar]
  );

  const handleChangeState = useCallback(
    (id: string) => {
    return null;
  },[]);

    // State to hold selected rows
    const [selectedRows, setSelectedRows] = useState<any>([]);

    // Function to handle row selection
    const handleSelectRow = useCallback((id: string, selectionMode: string)=>{
      // Check if the row is already selected
      const selectedIndex = selectedRows.indexOf(id);
      let newSelected:any = [];
  
      table.onSelectRow(id);
      if (selectedIndex === -1) {
        // If not selected, add to the selection
        newSelected = newSelected.concat(selectedRows, id);
      } else if (selectedIndex === 0) {
        // If first element is selected, remove it
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        // If last element is selected, remove it
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        // If middle element is selected, remove it
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
        
      }
  
      // Update the selected rows state
      setSelectedRows(newSelected);
    },
    [table, selectedRows]
    );

  return (
    <div>
      {/* Bread Crumb + Create Button */}
      <div className='flex items-center justify-between pb-3 h-12'>
        <CustomBreadcrumbs
          links={[
            { name: 'Staff', href: routes.parents },
            { name: 'Parents', href: routes.parents },
          ]}
        />
        <RoundedButton label='New Parent' backgroundColor='#637381' onClick={handleAddRow} icon='mingcute:add-line' />
      </div>
      
      {/* DataTable */}
      <DataTable
        tableName='Parents'
        deleteTitle='Parent'
        mainCol='username'
        table={table}
        tableCols={PARENT_COLUMNS}
        tableData={dataFiltered}
        filters = {filters}
        resetFilters = {resetFilters}
        denseHeight={denseHeight}
        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
        notFound={notFound}
        handleFilters={handleFilters}
        handleResetFilters={handleResetFilters}
        handleEditRow={handleEditRow}
        handleDeleteRow={handleDeleteRow}
        handleChangeState = {handleChangeState}
        onSelectRow={handleSelectRow} />
    </div>
  );
};

export default Parent;