import { useCallback, useEffect, useState } from "react";
import ChatList from "../../../components/common/chat/chat-list";
import CustomBreadcrumbs from "../../../components/minimal/custom-breadcrumbs";
import { useAuth } from "../../../hooks/useAuth";
import { routes } from "../../../routes/routes";
import axios from "axios";
import { getStorage } from "../../../hooks/use-local-storage";
import { CHAT_HOST } from "../../../config-global";
import { handleErrors } from "../../../handlers/axiosErrorsHandler";
import { enqueueSnackbar } from "notistack";
import { Conversation, Message } from "../../../types/chat";
import ChatWindow from "../../../components/common/chat/chat-window";
import { BallTriangle, Circles, InfinitySpin, RotatingSquare } from 'react-loader-spinner';
import ChatUser from "../../../components/common/chat/chat-user";

interface ChatProps {
}

const Chat: React.FC<ChatProps> = () => {
    const { setAuthenticated } = useAuth();
    const token = getStorage('Token');
    const user = JSON.parse(getStorage('User'));

    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [messages, setMessages] = useState<Message[]>([]);
    const [receiver, setReceiver] = useState<Conversation>();
    const [loadingConversations, setLoadingConversations] = useState<boolean>(false);
    const [loadingMessages, setLoadingMessages] = useState<boolean>(false);

    // Get Conversations (Users who Chat with him)
    useEffect(() => {
        const fetchData = async () => {
          setLoadingConversations(true); // Start loading
          try {
            await axios.get(`https://chat-staging.noody.net/conversations`, {
              headers: {
                'Authorization': `Bearer ${token}`,
              }
            }).then(res =>{
              var response = res?.data;
              setConversations(response?.data);
              setLoadingConversations(false); // Stop loading
            })
          } catch (error: any) {
            handleErrors(error, setAuthenticated, enqueueSnackbar);
            setLoadingConversations(false); // Stop loading
          }
        };
    
        fetchData();
      }, [token, setAuthenticated, enqueueSnackbar]);

    // Handle click on user to start chat with him
    const handleSelectionConversation = useCallback(
        async (user: Conversation) => {
          setLoadingMessages(true); // Start loading
          await axios.get(`${CHAT_HOST}/conversations/${user.id}/messages?page=1&perPage=10&isFirstFetch=true&withoutUnread=true`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'x-locale': 'en',
            }
          })
          .then(res => {
            if (res.status === 200) {
              var response = res?.data;
              setMessages(response?.data);
              setReceiver(user);
              setLoadingMessages(false); // Stop loading
            } else {
              setLoadingMessages(false); // Stop loading
            }
          })
          .catch(error => {
            handleErrors(error, setAuthenticated, enqueueSnackbar);
            setLoadingMessages(false); // Stop loading
          });
        },
        [token, setAuthenticated, enqueueSnackbar]
      );

    return (
        <div className="contents min-h-full">
            {/* Bread Crumb + Create Button */}
            <div className='flex items-center justify-between pb-3 h-12'> 
                <CustomBreadcrumbs
                    links={[
                        { name: 'Overview', href: routes.storyReports },
                        { name: 'Chat', href: routes.storyReports },
                    ]}
                />
            </div>
            <div className="flex h-5/6 rounded-2xl bg-white">
              <div className="w-1/4 flex flex-col" style={{borderRight: '1px solid rgb(20 20 20 / 16%)'}}>
                <ChatUser fullname={user.fullName} avatar='' />
                <label className="px-5 mt-5">Chat Users</label>
                {loadingConversations ? (
                  <div className="flex justify-center items-center h-full">
                    {/* <RotatingSquare color="#FF922E" width='180' /> */}
                    <InfinitySpin color="#FF922E" width='180' />
                  </div>
                ) : (
                  <ChatList conversations={conversations} onSelectConversation={handleSelectionConversation}/>
                )}
              </div>
              <div className="w-3/4">
                {loadingMessages ? (
                  <div className="flex justify-center items-center h-full">

                      {/* <RotatingSquare color="#FF922E" width='180' /> */}
                      <InfinitySpin color="#FF922E" width='180' />
                  </div>
                ) : (
                  <ChatWindow currentConversation={receiver} chatMessages={messages} />
                )}
                  {/* <ChatWindow currentConversation={receiver} chatMessages={messages} /> */}
              </div>
            </div>
        </div>
    );
};

export default Chat;