import { useChart } from '../../../../components/minimal/chart';
import { fNumber } from '../../../../utils/format-number';
import AppStatisticsReport from '../../../../components/minimal/common/AppStatisticsReport';

export default function ChildrenGender({ statistics }: { statistics?: any }) {
  const defaultStatistics = { maleCount: 0, femaleCount: 0 };
  const safeStatistics = statistics || defaultStatistics;
  // const { t } = useTranslate();
  // const { currentLang } = useLocales();

  // const maleK = statistics?.maleCount > 999 ? 'K' : '';
  // const femaleK = statistics?.femaleCount > 999 ? 'K' : '';

  const series = [
    {
      // label: `${t('common.Male')} ${statistics?.maleCount}`,
      label: `Male`,
      value: safeStatistics?.maleCount,
    },
    {
      // label: `${t('common.Female')} ${statistics?.femaleCount}`,
      label: `Female`,
      value: safeStatistics?.femaleCount,
    },
  ];

  const chartSeries = series.map((i) => i.value);

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: false,
      },
      foreColor: '#ffffff',
    },
    colors: ['#4D347C', '#FF9AA7'],
    labels: series.map((i) => i.label),
    legend: {
      width: 110,
      floating: false,
      position: 'bottom',
      horizontalAlign: 'left',
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value: number) => fNumber(value),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        customScale: 0.8,
        donut: {
          size: '90%',
          labels: {
            total: {
              show: false,
            },
            // {
            //   label: t('statistics.total'),
            //   formatter: (w: { globals: { seriesTotals: number[] } }) => {
            //     const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
            //     return fNumber(sum);
            //   },
            // },
          },
        },
      },
    },
  });

  return (
    <AppStatisticsReport
      // title={t('statistics.students.studentsGender')}
      title='Children Gender'
      sxTitle={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}
      //   series={values}
      variant="body2"
      series={chartSeries}
      options={chartOptions}
      reportType="donut"
      //   reportHeight={400}

      sx={{
        pt: 0.5,
        // height: 364,
        '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
          //   height: `100% !important`,
        },
        '& .apexcharts-legend': {
          //   height: 72,
          //   borderTop: `dashed 1px ${theme.palette.divider}`,
          //   top: `calc(${400 - 72}px) !important`,
        },
      }}
    />
  );
}
