import { ApexOptions } from 'apexcharts';

import { SxProps } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Card, { CardProps } from '@mui/material/Card';

// import { useTranslate } from 'src/locales';

import Chart from '../chart';

interface Props extends CardProps {
  title: string;
  series: any;
  reportType?: 'bar' | 'donut' | 'line' | 'area' | 'pie' | 'radialBar';
  options: ApexOptions;
  reportHeight?: number;
  variant?: any;
  sx?: SxProps;
  sxTitle?: any;
}

export default function AppStatisticsReport({
  title,
  series,
  reportType = 'bar',
  options,
  reportHeight = 376,
  variant = 'h4',
  sx,
  sxTitle,
}: Props) {
  // const { t } = useTranslate();

  return (
    <Card sx={{ borderRadius: 1, p: 2 }}>
      <CardHeader
        title={
          <Typography sx={{ ...sxTitle }} variant={variant}>
            {/* {t(title)} */}
            {title}
          </Typography>
        }
        sx={{ p: '0!important'}}
      />

      {/* <CardContent> */}
        <Chart
          dir="ltr"
          type={reportType}
          series={series}
          options={options}
          height={reportHeight}
          sx={{ ...sx }}
        />
      {/* </CardContent> */}
    </Card>
  );
}
