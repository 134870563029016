import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, Card, IconButton } from '@mui/material';
import Image from '../../../../components/minimal/image';
import { ASSETS_API, ASSETS_API_PREFIX, HOST_API } from '../../../../config-global';
import axios from 'axios';
import { getStorage } from '../../../../hooks/use-local-storage';
import { enqueueSnackbar } from '../../../../components/minimal/snackbar';
import { handleErrors } from '../../../../handlers/axiosErrorsHandler';
import { useAuth } from '../../../../hooks/useAuth';

interface PreviewDialogProps {
  open: boolean;
  onClose: () => void;
  rowData: Record<string, any> | null; // Adjust the type based on your row data structure
}

const PreviewDialog: React.FC<PreviewDialogProps> = ({ open, onClose, rowData }) => {
const { setAuthenticated } = useAuth();

  const token = getStorage('Token');
  const [isDeleting, setIsDeleting] = useState(false);

  const renderImageField = (imageUrl: string)=>(
    <Card sx={{ p: 1, my: 0.5 }}>
      <Image ratio="1/1" alt="gallery" src={ASSETS_API + ASSETS_API_PREFIX + imageUrl} 
      sx={{borderRadius: 1, width: '100%', maxHeight: '350px', border: '1px', padding: 2}} 
      effect='opacity'
      />
    </Card>
  )
  
  const renderVideoField = (videoUrl: string) =>{
    return (
      <Card
        sx={{my: 0.5}} 
        style={{ paddingTop: "56.25%"}}>
        <iframe src={`https://iframe.mediadelivery.net/embed/160614/${videoUrl}?autoplay=false&loop=false&muted=false&preload=true&responsive=true`} 
          title={`${videoUrl}`}
          loading="lazy" 
          style={{ position: "absolute", top: 0, height: "100%", width: "100%", padding: 8, borderRadius: 24}}
          allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" 
          allowFullScreen>
          </iframe>
      </Card>
  )}

  const handleDelete = async () => {
    if (!rowData?.id) return;

    await axios.delete(`${HOST_API}/api/admin/user-stories/${rowData?.id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'x-locale': 'en',
      }
    })
    .then(res => {
      if (res.status === 200) {
        onClose();
        enqueueSnackbar('Delete success!', {
          // variant: 'success',
          // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        });
      } else {
        console.error('Delete request failed');
      }
    })
    .catch(error => {
      handleErrors(error, setAuthenticated, enqueueSnackbar);
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>View Story</DialogTitle>
      <DialogContent>
      {rowData ? (
          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              {rowData.mediaType.label === 'Image' ?
                renderImageField(rowData.mediaUrl) : renderVideoField(rowData.mediaUrl)
              }
            </Box>
          </Box>
        ) : (
          <Typography>No data available</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete} disabled={isDeleting}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewDialog;
