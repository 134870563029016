import React from 'react';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';

import { Card } from '@mui/material';

import AdminForm from './components/admin-form';

import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';

// ----------------------------------------------------------------------

interface CreateAdminProps {
}

// ----------------------------------------------------------------------

const CreateAdmin: React.FC<CreateAdminProps> = () => {

  const navigate = useNavigate();

  const handleCloseForm = () =>{
    navigate(routes.admins);
  }

    return(
        <div>
            {/* Bread Crumb */}
            <div className='flex items-center justify-between pb-3 h-12'>
              <CustomBreadcrumbs
                links={[
                  { name: 'Staff', href: routes.admins },
                  { name: 'Admins', href: routes.admins },
                  { name: 'Create' },
                ]}
              />
            </div>
            {/* Admin Form */}
            <div className='flex flex-row min-h-96'>
                {/* <h2 className='uppercase font-bold w-1/2'>Info</h2> */}
                <h2 className='uppercase font-bold w-1/5'>Info</h2>
                {/* <Card className='w-1/2 p-5'> */}
                <Card className='w-4/5 p-5'>
                  <AdminForm onClose={handleCloseForm} />
                </Card>
            </div>
        </div>
    )
};

export default CreateAdmin;