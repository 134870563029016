import React from 'react';
// import './ChatList.css';
import { Conversation } from '../../../types/chat';
import ChatUser from './chat-user';
import { getStorage } from '../../../hooks/use-local-storage';

interface ChatListProps {
    // conversations: string[];
    conversations: Conversation[];
    onSelectConversation: (conversation: Conversation) => void;
}

const ChatList: React.FC<ChatListProps> = ({ conversations, onSelectConversation }) => {
    return (
        <div className="chat-list flex flex-col">
            {/* <h2>Conversations</h2> */}
            {/* <h2>{user.fullName}</h2> */}
            {/* <ChatUser fullname={user.fullName} avatar='' /> */}
            {/* mt-4 */}
            {/* <hr className='mt-4'/> */}
            {/* <label className="px-5 mt-5">Chat Users</label> */}
            <ul>
                {conversations.map((conversation, index) => (
                    <li key={index} onClick={() => onSelectConversation(conversation)} style={{cursor: 'pointer'}}>
                        {/* {conversation} */}
                        <ChatUser fullname={conversation.info.name} avatar={conversation.info.imageURL} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ChatList;
