import React from 'react';
import CTableStable from '../../../components/common/table/custom-table-stable';

interface ActivityProps {
}

const Activity: React.FC<ActivityProps> = () => {
  const columns = [
    { Header: 'Column 1', accessor: 'column1' },
    { Header: 'Column 2', accessor: 'column2' },
  ];

  const data = [
    { column1: 'Data 1-1', column2: 'Data 1-2' },
    { column1: 'Data 2-1', column2: 'Data 2-2' },
    { column1: 'Data 3-1', column2: 'Data 3-2' },
    { column1: 'Data 4-1', column2: 'Data 4-2' },
    { column1: 'Data 5-1', column2: 'Data 5-2' },
    { column1: 'Data 6-1', column2: 'Data 6-2' },
    { column1: 'Data 7-1', column2: 'Data 7-2' },
    { column1: 'Data 8-1', column2: 'Data 8-2' },
  ];
  
  return (
    <div>
      <CTableStable tableName="Activity View" columns={columns} data={data} />
    </div>
  );
};

export default Activity;