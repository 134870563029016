import React, { useMemo, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';

import { useNavigate, useParams } from 'react-router-dom';

import { getCategorySchema } from '../category-schema';

import { fData } from '../../../../utils/format-number';

import { ASSETS_API, ASSETS_API_PREFIX, HOST_API } from '../../../../config-global';
import axios from 'axios';
import { handleErrors } from '../../../../handlers/axiosErrorsHandler';

import { routes } from '../../../../routes/routes';

import { getStorage } from '../../../../hooks/use-local-storage';
import { useAuth } from '../../../../hooks/useAuth';

import { Box, Card, Grid, Stack, Typography, CircularProgress, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { MuiColorInput } from 'mui-color-input';

import { Controller, useForm } from 'react-hook-form';
import FormProvider, {
    RHFRoundedTextField, 
    RHFSwitch, 
    RHFUpload,
  } from '../../../../components/minimal/hook-form';
import { useSnackbar } from '../../../../components/minimal/snackbar';
import Image from '../../../../components/minimal/image';
import Iconify from '../../../../components/minimal/iconify';

// ----------------------------------------------------------------------

const styles = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '9999px',
    '& fieldset': {
        borderColor: 'black',
    },
    '&:hover fieldset': {
        borderColor: '#f87171',
    },
    '&.Mui-focused fieldset': {
        borderColor: '#f87171',
    },
  },
  '& label': {
      // color: 'red',
  },
  '& label.Mui-focused': {
      color: '#f87171',
  },
}

// ----------------------------------------------------------------------

interface CategoryFormProps {
  onClose: VoidFunction;
}

// ----------------------------------------------------------------------

const CategoryForm: React.FC<CategoryFormProps> = ({onClose}) => {
  const { setAuthenticated } = useAuth();

  const [loading, setLoading] = useState<boolean>(true); 

  const [objData, setObjData] = useState<any>({});
  
  const token = getStorage('Token');
  
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams(); // Extract categoryId from the URL
  const isUpdate = id !== undefined; // Check if it's an update

  const CategorySchema = useMemo(() => getCategorySchema(isUpdate), [isUpdate]);

  //must default values attributes is equal to categorySchema
  const defaultValues = useMemo(
    () => ({
      title: '',
      titleAr: '',
      coverImage: null,
      backgroundColor: '',
      numberChallengeWinBadge: 1,
      published: true,
    }),
    []
  );

  const methods = useForm({
      resolver: yupResolver(CategorySchema),
      defaultValues,
  });

  const {
    // reset,
    // watch,
    control,
    setValue,
    handleSubmit,
    // formState: { errors },
    formState: { isSubmitting },
  } = methods;

  const [color, setColor] = React.useState('')

  const handleChangeColor = useCallback((color:string) => {
    setColor(color); // set the color in color picker text field
    setValue('backgroundColor', color, { shouldValidate: true }); // set the color value in object key backgroundColor
  },[setValue, setColor]);

  const handleDropFile = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('coverImage', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveImage = useCallback(() => {
    setObjData((prevObjData: any) => {
      return { ...prevObjData, coverImageUrl: '' };
    });
  }, [setObjData]);

  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formData = new FormData();
      formData.append('translations[0][locale]', 'en');
      formData.append('translations[0][title]', data.title);
      formData.append('translations[1][locale]', 'ar');
      formData.append('translations[1][title]', data.titleAr);
      if(!isUpdate || data.coverImage){
        formData.append('coverImage', data.coverImage); // Assuming coverImage is a File object
      }
      formData.append('backgroundColor', data.backgroundColor);
      formData.append('numberChallengeWinBadge', String(1));
      formData.append('published', String(data?.published));

      if (isUpdate) {
        // Update operation
        await axios.patch(`${HOST_API}/api/admin/challenge-categories/${id}`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-locale': 'en',
          }
        });
      } else {
        // Create operation
        await axios.post(`${HOST_API}/api/admin/challenge-categories`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-locale': 'en',
          }
        });
      }

      enqueueSnackbar(isUpdate ? 'Update success!' : 'Create success!', {
        // variant: 'success',
        // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
      });
      navigate(routes.categories);
    } catch (error:any) {
      // reset();
      handleErrors(error, setAuthenticated, enqueueSnackbar);
    }
  });

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(`${HOST_API}/api/admin/challenge-categories/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-locale': 'en',
          }
        });
        const categoryData = response?.data?.data; // Assuming the API response contains the category data
        setObjData(categoryData);
        // Populate form fields with fetched data
        
        // setValue('title', categoryData.title || '');
        // Find the translation object with locale 'en' in the translations array
        const enTranslation = categoryData.translations.find((translation: any) => translation.locale === 'en');
        // If 'en' translation exists, set titleAr to its title, otherwise set it to an empty string
        const titleEnValue = enTranslation ? enTranslation.title : '';
        setValue('title', titleEnValue);
        // setValue('titleAr', categoryData.titleAr || '');
        // Find the translation object with locale 'ar' in the translations array
        const arTranslation = categoryData.translations.find((translation: any) => translation.locale === 'ar');
        // If 'ar' translation exists, set titleAr to its title, otherwise set it to an empty string
        const titleArValue = arTranslation ? arTranslation.title : '';
        setValue('titleAr', titleArValue);

        // if (typeof file === 'string') return <img src={file} alt="Preview" />;
        // setValue('coverImage', categoryData?.coverImageUrl || null); // 
        handleChangeColor(categoryData?.backgroundColor);
        // setValue('backgroundColor', categoryData.backgroundColor || '');
        // setValue('numberChallengeWinBadge', categoryData.numberChallengeWinBadge || 0);
        
        setValue('published', categoryData?.published);
        // setValue('published', categoryData?.published || true);
        // setValue('standard', categoryData?.standard || true);

        setLoading(false); // Set loading to false when data fetching is complete
      } catch (error) {
        // Handle error, e.g., display an error message
        // console.error('Error fetching category data:', error);
        handleErrors(error, setAuthenticated, enqueueSnackbar);
        setLoading(false); // Set loading to false even if there's an error
      }
    };

    if (isUpdate) { // Update State
      fetchCategoryData();
    }else{ // Create State
      setLoading(false);
    }
  }, [ id, token, setValue, handleChangeColor, isUpdate, setAuthenticated, enqueueSnackbar, setObjData]);

  const renderColorPickerField = (name: any, label: string)=>(
    <Controller
              name={name}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <MuiColorInput 
                  {...field}
                  label={label}
                  name={name} 
                  value={color} 
                  onChange={handleChangeColor} 
                  isAlphaHidden={true}
                  format="hex" 
                  size="small"
                  error={!!error}
                  helperText={error?.message}
                  sx={styles} />
              )} />
  );

  const renderUploadFileField = (name: any, allowedExt: string, accept: any)=>(
    <RHFUpload
      name={name}
      maxSize={3145728}
      accept= {accept}// Specify accepted file types
      onDrop={handleDropFile}
      onDelete={() => setValue(name, null, { shouldValidate: true })}
      helperText={
        <Typography
          variant="caption"
          sx={{
            mt: 3,
            mx: 'auto',
            display: 'block',
            textAlign: 'center',
            color: 'text.disabled',
          }}
        >
          {/* Allowed *.jpeg, *.jpg, *.png, *.gif */}
          {allowedExt}
          <br /> max size of {fData(3145728)}
        </Typography>
      }
    />
  )

  const renderImageField = (imageUrl: string)=>(
    <div style={{ position: 'relative' }}>
      <Card sx={{ p: 1, mt: 0.5 }}>
        <Image ratio="1/1" alt="gallery" src={ASSETS_API + ASSETS_API_PREFIX + imageUrl} 
        sx={{borderRadius: 1, width: '100%', height:'300px', border: '1px', padding: 2}} 
        effect='opacity'
        />
      </Card>

      {/* Remove Image Icon */}
      <Box sx={{ position: 'absolute', top: -12, right: -12 }}>
        <IconButton onClick={handleRemoveImage} 
        sx={{
          backgroundColor: '#FF922E',
          '&:hover': {backgroundColor: '#FF7F00',}
        }}>
          <Iconify icon={'solar:trash-bin-trash-bold'} color='white'/>
        </IconButton>
      </Box>
    </div>
  )

  return(
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {/* Render loader if loading is true */}
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container spacing={3}> 
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box
                columnGap={2} // gap between form columns
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}>
                <RHFRoundedTextField name="title" label="Title (En)" />
                <RHFRoundedTextField name="titleAr" label="Title (Ar)" />
              </Box>
              <Stack sx={{ mt: 3 }}>
                {renderColorPickerField("backgroundColor", "Background Color")}
              </Stack>
              
              {/* Upload File Rectangle with Dropzone and Svg Image */}
              <Box sx={{ mt: 3 }}>
                <Typography
                  variant="caption"
                  sx={{
                    display: 'block',
                    fontSize: '11pt',
                  }}>
                  Cover Image
                </Typography>
                {isUpdate && objData.coverImageUrl ? ( // Check if it's an update and coverImage exists
                  renderImageField(objData.coverImageUrl)
                ) : (
                  renderUploadFileField("coverImage", "Allowed *.jpeg, *.jpg, *.png",
                  { 'image/png': ['.png'], 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg']},)
                )}
              </Box>

              <Stack sx={{ mt: 3 }}>
                <RHFSwitch name="published" label="Published" />
              </Stack>

              {/* Form Actions Bar */}
              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" color="primary" size="large" loading={isSubmitting}
                  style={{ borderRadius: 20,textTransform: 'capitalize' }}
                  className="bg-gradient-to-br from-red-400 to-orange-400 capitalize">
                    {isUpdate ? 'Update Category' : 'Create Category'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}
  </FormProvider>
  )
};

export default CategoryForm;