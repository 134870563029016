import React, { useCallback, useState ,useEffect } from 'react';

import isEqual from 'lodash/isEqual';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';
import { 
  useTable,  
  emptyRows,
  getComparator,
} from '../../../components/minimal/table';

import { IStoryReportTableFilters, IStoryReportTableFilterValue } from '../../../types/story-report';
import { STORY_REPORT_COLUMNS } from '../../../consts/story-report';

import DataTable from '../../../components/common/data-table/data-table';

import { applyFilter } from '../../../utils/data-table-filter';

import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { HOST_API } from '../../../config-global';
import { handleErrors } from '../../../handlers/axiosErrorsHandler';
import { getStorage } from '../../../hooks/use-local-storage';
import { routes } from '../../../routes/routes';

import { useAuth } from '../../../hooks/useAuth';

import { useSnackbar } from '../../../components/minimal/snackbar';
import PreviewDialog from './components/preview-dialog';

// ----------------------------------------------------------------------

interface StoryReportProps {
}

// ----------------------------------------------------------------------

const defaultFilters: IStoryReportTableFilters = {
  title: '',
};

// ----------------------------------------------------------------------

const StoryReport: React.FC<StoryReportProps> = () => {
  const { setAuthenticated } = useAuth();

  const token = getStorage('Token');

  const { enqueueSnackbar } = useSnackbar();

  const table = useTable();

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  
  const [filters, setFilters] = useState(defaultFilters);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rowData, setRowData] = useState<any>(null);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.get(`${HOST_API}/api/admin/user-stories`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-locale': 'en',
          }
        }).then(res =>{
          var response = res.data;
          setTableData(response.data.items);
        })
      } catch (error: any) {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      }
    };

    fetchData();
  }, [token, setAuthenticated, enqueueSnackbar]);

  const denseHeight = table.dense ? 52 : 72;

  const resetFilters = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && resetFilters) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IStoryReportTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleViewRow = useCallback(
    async (id: string) => {
      await axios.get(`${HOST_API}/api/admin/user-stories/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'x-locale': 'en',
        }
      })
      .then(res => {
        setRowData(res.data.data);
        setIsDialogOpen(true);
      })
      .catch(error => {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      });
    },
    []
  );

  const handleChangeState = useCallback(
    (id: string) => {
    return null;
  },[]);

  // State to hold selected rows
  const [selectedRows, setSelectedRows] = useState<any>([]);

  // Function to handle row selection
  const handleSelectRow = useCallback((id: string, selectionMode: string)=>{
    // Check if the row is already selected
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected:any = [];

    table.onSelectRow(id);
    if (selectedIndex === -1) {
      // If not selected, add to the selection
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      // If first element is selected, remove it
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      // If last element is selected, remove it
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      // If middle element is selected, remove it
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
      
    }

    // Update the selected rows state
    setSelectedRows(newSelected);
  },
  [table, selectedRows]
  );

  return (
    <div>
      {/* Bread Crumb + Create Button */}
      <div className='flex items-center justify-between pb-3 h-12'> 
        <CustomBreadcrumbs
          links={[
            { name: 'Overview', href: routes.storyReports },
            { name: 'Story Reports', href: routes.storyReports },
          ]}
        />
      </div>

      {/* DataTable */}
      <DataTable
        tableName='Story Reports'
        deleteTitle='Story Report'
        mainCol='title'
        table={table}
        tableCols={STORY_REPORT_COLUMNS}
        tableData={dataFiltered}
        filters = {filters}
        resetFilters = {resetFilters}
        denseHeight={denseHeight}
        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
        notFound={notFound}
        handleFilters={handleFilters}
        handleResetFilters={handleResetFilters}
        handleViewRow={handleViewRow}
        handleChangeState={handleChangeState}
        onSelectRow={handleSelectRow}
        hasSearch = {false}
        hasEdit={false}
        hasDelete={false}
        hasView={true} />

      <PreviewDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        rowData={rowData}
      />
    </div>
  );
};

export default StoryReport;