// Login.tsx
import React, { useState } from 'react';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';
import { useBoolean } from '../../hooks/use-boolean';

import { Alert, Card, IconButton, InputAdornment, Stack } from '@mui/material';

import axios from 'axios';

import FormProvider, {
  RHFRoundedTextField,
} from '../../components/minimal/hook-form';
import Iconify from '../../components/minimal/iconify';
import LoadingButton from '@mui/lab/LoadingButton';

import noIMG from '../../assets/images/no.svg'
import odyIMG from '../../assets/images/ody.svg'

import { HOST_API } from '../../config-global';

interface LoginProps {
}

const DeleteAcount: React.FC<LoginProps> = () => {
  const [errorMsg, setErrorMsg] = useState('');

  const password = useBoolean();

  const LoginSchema = Yup.object().shape({
    // email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    username: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await axios.post(`${HOST_API}/api/user/auth/delete-account`, 
      { "username":data.username, "password": data.password }, {
        headers: {
          'Content-Type': 'application/json',
          'x-locale': 'en',
        }
      })
      .then(res => {
        var response = res.data;
        setErrorMsg('');
        reset();
        alert(`Account (${response.data.username}) Deleted Successfully`);
        // enqueueSnackbar('Account Deleted!', {
          // variant: 'success',
          // anchorOrigin: { vertical: 'bottom', horizontal: 'right' }
        // });
      })
    } catch (error:any) {
      setErrorMsg(typeof error === 'string' ? error : error.response?.data?.errors || 'An error occurred! please try again later.');
    }
  });

  return (
    <div className="App bg-gray-100 min-h-screen flex">
    {/* login gradient */}
    <div className="w-full relative">

      <div className='absolute top-0 left-0 z-10'>
        {/* <img src={noodyIMG} alt="" /> */}
        <h2 className='text-white text-3xl font-bold tracking-widest p-5'>NOODY</h2>
      </div>
      
      <div className="bg-gradient-to-br from-red-400 to-orange-400 
         h-full flex items-center justify-center relative">

        <div className="absolute h-auto">
          <img src={noIMG} alt="" className="top-0" />
          <img src={odyIMG} alt="" className="bottom-0" />
        </div>

        {/* delete account form */}
        <Card className='p-5 flex flex-col w-1/3'
          sx={{borderRadius: 5, zIndex: 2}}>

          <span className="text-xl font-bold">Delete My Account</span>

          <div className="mt-8">
            <div className="">
              <FormProvider methods={methods} onSubmit={onSubmit}>
                <Stack spacing={2.5}>
                  {!!errorMsg && <Alert severity="error" style={{ borderRadius: 20 }}>{errorMsg}</Alert>}
                  <RHFRoundedTextField name="username" label="Username" type="text" helperText=""  autoComplete="new-password" />
                  <RHFRoundedTextField name="password" label="Password"helperText="" autoComplete="new-password"
                   type={password.value ? 'text' : 'password'}
                   InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={password.onToggle} edge="end">
                          <Iconify icon={password.value ? 'solar:eye-broken' : 'solar:eye-closed-bold'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                   }} />
             
                  <LoadingButton
                    fullWidth
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    style={{ borderRadius: 20 }}
                    className="bg-gradient-to-br from-red-400 to-orange-400 capitalize">
                      Delete
                    </LoadingButton>
                    <span className='text-center hidden'>V0.0.3</span>
                </Stack>
              </FormProvider>
            </div>
          </div>
        </Card>
      </div>
    </div>
  </div>
  );
};

export default DeleteAcount;