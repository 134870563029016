import React, { useMemo, useCallback } from 'react';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';

import axios from 'axios';

import { Box, Card, Grid, Stack, Button, IconButton, InputAdornment, Typography } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import FormProvider, {
    RHFRoundedTextField, 
    RHFSwitch,
    RHFUploadAvatar,
  } from '../../../../components/minimal/hook-form';
import Iconify from '../../../../components/minimal/iconify';
import { useSnackbar } from '../../../../components/minimal/snackbar';

import { IUserItem } from '../../../../types/user';
import { fData } from '../../../../utils/format-number';

import { useBoolean } from '../../../../hooks/use-boolean';
import { HOST_API } from '../../../../config-global';

import { useAuth } from '../../../../hooks/useAuth';
import { handleErrors } from '../../../../handlers/axiosErrorsHandler';

// ----------------------------------------------------------------------

interface UserFormProps {
  onClose: VoidFunction;
  currentUser?: IUserItem;
}

// ----------------------------------------------------------------------

const UserForm: React.FC<UserFormProps> = ({currentUser, onClose}) => {
  const { setAuthenticated } = useAuth();

  const password = useBoolean();
  const { enqueueSnackbar } = useSnackbar();

  const UserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phoneNumber: Yup.string().required('Phone number is required'),
    address: Yup.string().required('Address is required'),
    country: Yup.string().required('Country is required'),
    company: Yup.string().required('Company is required'),
    state: Yup.string().required('State is required'),
    city: Yup.string().required('City is required'),
    role: Yup.string().required('Role is required'),
    zipCode: Yup.string().required('Zip code is required'),
    avatarUrl: Yup.mixed<any>().nullable().required('Avatar is required'),
    // not required
    status: Yup.string(),
    isVerified: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || '',
      city: currentUser?.city || '',
      role: currentUser?.role || '',
      email: currentUser?.email || '',
      password: currentUser?.password || '',
      state: currentUser?.state || '',
      status: currentUser?.status || '',
      address: currentUser?.address || '',
      country: currentUser?.country || '',
      zipCode: currentUser?.zipCode || '',
      company: currentUser?.company || '',
      avatarUrl: currentUser?.avatarUrl || null,
      phoneNumber: currentUser?.phoneNumber || '',
      isVerified: currentUser?.isVerified || true,
    }),
    [currentUser]
  );

  const methods = useForm({
      resolver: yupResolver(UserSchema),
      defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
      try {
        await axios.post(`${HOST_API}/api/User/auth/login`, 
        { "name":data.name, "phoneNumber": data.phoneNumber, "email": data.email }, {
          headers: {
              'Content-Type': 'application/json',
          }
        })
        .then(res => {
          enqueueSnackbar(currentUser ? 'Update success!' : 'Create success!');
        })
      } catch (error:any) {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      }
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
  
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
  
      if (file) {
        setValue('avatarUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

    return(
      <FormProvider methods={methods} onSubmit={onSubmit}>
      {/* <div class"container"></div> */}
      <Grid container spacing={3}> 
        {/* <div class="col-md-4 col-xs-12"></div> */}
        <Grid item xs={12} md={4}>
          {/* , maxHeight: '26rem' */}
          <Card sx={{ pt: 5, pb: 5, px: 3, minHeight: '26rem' }}>
            {/* {currentUser && (
              <Label
                color={
                  (values.status === 'active' && 'success') ||
                  (values.status === 'banned' && 'error') ||
                  'warning'
                }
                sx={{ position: 'absolute', top: 24, right: 24 }}
              >
                {values.status}
              </Label>
            )} */}

            {/* Rounded Upload File for Avatar/User Profile*/}
            {/* helperText is a text displayed under upload file to tell the user what to upload */}
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>

            {/* {currentUser && (
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        checked={field.value !== 'active'}
                        onChange={(event) =>
                          field.onChange(event.target.checked ? 'banned' : 'active')
                        }
                      />
                    )}
                  />
                }
                label={
                  <>
                    <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                      Banned
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      Apply disable account
                    </Typography>
                  </>
                }
                sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
              />
            )} */}

            <RHFSwitch
              name="isVerified"
              labelPlacement="start"
              label={
                <>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Email Verified
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Disabling this will automatically send the user a verification email
                  </Typography>
                </>
              }
              sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
            />

            {currentUser && (
              <Stack justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                <Button variant="soft" color="error">
                  Delete User
                </Button>
              </Stack>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, minHeight: '26rem' }}>
            <Box
              rowGap={3} // gap between form rows
              columnGap={2} // gap between form columns
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFRoundedTextField name="name" label="Full Name" autoComplete="new-password" />
              <RHFRoundedTextField name="phoneNumber" label="Phone Number" />
              <RHFRoundedTextField name="email" label="Email Address" autoComplete="new-password" />
              <RHFRoundedTextField name="password" label="Password"
               type={password.value ? 'text' : 'password'}
               InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={password.onToggle} edge="end">
                      <Iconify icon={password.value ? 'solar:eye-broken' : 'solar:eye-closed-bold'} />
                    </IconButton>
                  </InputAdornment>
                ),
               }} />
              <RHFRoundedTextField name="state" label="State/Region" />
              <RHFRoundedTextField name="city" label="City" />
              <RHFRoundedTextField name="address" label="Address" autoComplete="new-password" />
              <RHFRoundedTextField name="zipCode" label="Zip/Code" />
              <RHFRoundedTextField name="company" label="Company" autoComplete="new-password" />
              <RHFRoundedTextField name="role" label="Role" />
              
            </Box>

            {/* Form Actions Bar */}
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              {/* <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!currentUser ? 'Create User' : 'Save Changes'}
              </LoadingButton> */}
              <LoadingButton type="submit" variant="contained" color="primary" size="large" loading={isSubmitting}
                style={{ borderRadius: 20 }}
                className="bg-gradient-to-br from-red-400 to-orange-400 capitalize">
                  {!currentUser ? 'Create User' : 'Save User'}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
    )
};

export default UserForm;