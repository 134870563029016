// Login.tsx
import React from 'react';
import LoginForm from './components/login-form';

interface LoginProps {
}

const Login: React.FC<LoginProps> = () => {
  return (
    <div>
      <LoginForm />
    </div>
  );
};

export default Login;