import { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// import { useTranslate } from 'src/locales';

import { Card, FormControl, Select } from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import CardHeader from '@mui/material/CardHeader';
import ButtonBase from '@mui/material/ButtonBase';
import { usePopover } from '../../../../components/minimal/custom-popover';
import Chart, { useChart } from '../../../../components/minimal/chart';

// ----------------------------------------------------------------------

type dates = {
  months: { month: number }[];
};

type maleCountPerMonths = {
  months: { maleCount: number }[];
};

type femaleCountPerMonths = {
  months: { femaleCount: number }[];
};

export default function TotalChildren({ statistics }: { statistics?: any }) {
//   const { t } = useTranslate();
  const [seriesData, setSeriesData] = useState('Monthly');
  const [yearFilter, setYearFilter] = useState<number>(0);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = statistics?.totalChildrenPerMonth?.map((item: dates, index: number) =>
    item?.months.map((m: { month: number }) => (index === yearFilter ? months[m.month - 1] : []))
  ) || [];

  const monthlyMale = statistics?.totalChildrenPerMonth?.map(
    (item: maleCountPerMonths) =>
      item?.months.map((m: { maleCount: number }) => m?.maleCount) || []
  ) || [];

  const monthlyFemale = statistics?.totalChildrenPerMonth?.map(
    (item: femaleCountPerMonths) =>
      item?.months.map((m: { femaleCount: number }) => m?.femaleCount) || []
  ) || [];
  
  const yearlyMale = statistics?.totalChildrenPerMonth?.map(
    (item: { maleCount: number }) => item?.maleCount || 0
  ) || [];

  const yearlyFemale = statistics?.totalChildrenPerMonth?.map(
    (item: { femaleCount: number }) => item?.femaleCount || 0
  ) || [];

  const year = statistics?.totalChildrenPerMonth?.map((item: { year: number }) => item?.year) || [];

  const chart = {
    categories: seriesData === 'Monthly' && date && date[yearFilter] ? date[yearFilter] : year,
    series: [
      {
        year: 'Monthly',
        data: [
          {
            name: 'Male',
            data: monthlyMale?.[yearFilter] || [],
          },
          {
            name: 'Female',
            data: monthlyFemale?.[yearFilter] || [],
          },
        ],
      },
      {
        year: 'Yearly',
        data: [
          {
            name: 'Male',
            data: yearlyMale || [],
          },
          {
            name: 'Female',
            data: yearlyFemale || [],
          },
        ],
      },
    ],
  };

  const title = 'Total Children';
  const filter = [
    // t('statistics.students.daily'),
    'Yearly',
    'Monthly',
  ];
  const {
    categories,
    series,
  } = chart;

  const chartOptions = useChart({
    colors: ['#4D347C', '#FF9AA7'],
    legend: {
      position: 'bottom',
      offsetX: 300,
      horizontalAlign: 'left',
    },
    xaxis: {
      categories,
    },
  });

  return (
    <Grid>
      <Card>
        <CardHeader
          title={title}
          //   subheader={subheader}
          action={
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Box sx={{ width: 120 }}>
                <FormControl fullWidth>
                  {seriesData === 'Monthly' && (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      // disabled={seriesData !== t('statistics.students.monthly')}
                      size="small"
                      fullWidth
                      defaultValue="all"
                      displayEmpty
                    >
                      <MenuItem disabled value="all">
                        {' '}
                        {'Year'}
                      </MenuItem>
                      {year.length === 0 ? (
                        <MenuItem disabled>No Data Available</MenuItem>
                      ) : (
                        year.map((item: number, index: number) => (
                          <MenuItem value={item} onClick={() => setYearFilter(index)}>
                            {item}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  )}
                </FormControl>
              </Box>

              <Card sx={{ display: 'flex', px: 2, py: 0.5 }}>
                {filter.map((item) => (
                  <ButtonBase
                    key={item + 'children'}
                    onClick={() => setSeriesData(item)}
                    sx={{
                      py: 0.5,
                      px: 1.5,
                      borderRadius: 3,
                      typography: 'subtitle2',
                      bgcolor: item == seriesData ? '#005639' : '',
                      color: item == seriesData ? 'white' : 'black',
                    }}
                  >
                    {item}
                  </ButtonBase>
                ))}
              </Card>
            </Box>
          }
        />

        {series.map((item) => (
          <Box key={item.year} sx={{ mt: 0, mx: 0 }}>
            {item.year === seriesData && (
              <Chart
                dir="ltr"
                type="line"
                series={item.data}
                options={chartOptions}
                width="100%"
                height={364}
              />
            )}
          </Box>
        ))}
      </Card>
    </Grid>
  );
}
