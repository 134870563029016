import { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import Iconify from '../../minimal/iconify';
import CustomPopover, { usePopover } from '../../minimal/custom-popover';

import { ITableFilterValue, ITableFilters } from '../../../types/filter';
import RoundedTextField from '../text-fields/rounded-text-field';

// ----------------------------------------------------------------------

type Props = {
  tableName?: string;
  filters: ITableFilters;
  onFilters: (name: string, value: ITableFilterValue) => void;
  hasSearch?: boolean;
};

export default function DataTableToolbar({
  tableName = '',
  filters,
  onFilters,
  hasSearch,
}: Props) {
  const popover = usePopover();

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters(Object.keys(filters)[0], event.target.value);
    },
    [onFilters, filters]
  );

  return (
    <div>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        justifyContent={'space-between'}
        width={1}
        sx={{ //Table Toolbar Styles
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
          py: 2,
        }}>

        <h2 className='font-bold'>{tableName}</h2>

        <Stack direction="row" alignItems="center" spacing={2} >
        {
        hasSearch?
          <RoundedTextField value={Object.values(filters)[0]} onChange={handleFilterName} label="Search..." 
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }} />
          : null
        }

          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            popover.onClose();
          }}>
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}>
          <Iconify icon="solar:import-bold" />
          Import
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}>
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
    </div>
  );
}
