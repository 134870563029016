// Header.tsx
import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '../../components/common/icon/icon';

import ukIMG from '../../assets/images/uk-flag.png';
import notificationIMG from '../../assets/icons/notification.svg';
import avatarIMG from '../../assets/images/avatar.svg';

import { useAuth } from '../../hooks/useAuth';
import { setAuthenticatedStatus } from '../../interceptors/auth';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps & { open?: boolean }>(
  ({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    background: 'linear-gradient(to right, #f87171, #fb923c)',
  }),
);

interface HeaderProps {
  handleDrawerOpen: () => void;
  open: boolean;
}

const Header: React.FC<HeaderProps> = ({ handleDrawerOpen, open }) => {
  const { setAuthenticated } = useAuth();

  const handleLogout = () => {
    // Set Authenticated to false
    // change Authenticated state value in localStorage (for refresh action) because state return to default value if refresh page
    setAuthenticatedStatus(false);
    // change global Authenticated state current value
    setAuthenticated(false);
  };

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <div className="flex items-center flex-row justify-between w-full">
          <div className="flex flex-row items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              {/* Mini variant drawer */}
              Dashboard
            </Typography>
          </div>

          <div className="flex items-center gap-2">
            {/* Language */}
            <img src={ukIMG} alt="" className="rounded-md h-6 w-8 mx-0.5 cursor-pointer"/>

            {/* Notifications */}
            <div className="rounded-full overflow-hidden h-10 w-10 border-2 border-solid border-gray-100 bg-gray-100 
            flex items-center justify-center cursor-pointer">
              <Icon src={notificationIMG} />
            </div>

            {/* Profile image */}
            {/* with h-10 and w-10, the height and width of the profile image will be 40 pixels each (10 units * 4 pixels/unit). */}
            <div className="rounded-full overflow-hidden h-10 w-10 border-2 border-solid border-gray-100 p-0.5 cursor-pointer"
            onClick={handleLogout}>
              <img
                src={avatarIMG}  // Replace with your image path
                alt="Profile"
                className="rounded-full h-full w-full object-contain"
                style={{ backgroundColor: '#FCEDDF' }}
              />
            </div>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
