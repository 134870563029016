import React, { useEffect, useState } from 'react';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';

import { Box, Card, CircularProgress } from '@mui/material';
import { routes } from '../../../routes/routes';
import { useSocketIO } from '../../../utils/web-socket/use-socket';
import StatisticCard from './components/statistic-card';
import TotalChallenges from './components/challenge-yearly';
import TotalUserChallenges from './components/user-challenge-yearly';
import AgeRanges from './components/age-range';
import ChildrenGender from './components/children-gender';
import TotalChildren from './components/children-yearly';
import axios from 'axios';
import { HOST_API } from '../../../config-global';
import { getStorage } from '../../../hooks/use-local-storage';
import { handleErrors } from '../../../handlers/axiosErrorsHandler';
import { useAuth } from '../../../hooks/useAuth';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

interface DashboardProps {
}

// ----------------------------------------------------------------------

const Dashboard: React.FC<DashboardProps> = () => {
  const { setAuthenticated } = useAuth();
  const token = getStorage('Token');
  const { enqueueSnackbar } = useSnackbar();

  const [statistics, setStatistics] = useState<any>(); 
  const [loading, setLoading] = useState<boolean>(true);

  useSocketIO();

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        await axios.get(`${HOST_API}/api/admin/statistics`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'x-locale': 'en',
          }
        }).then(res => {
          var response = res.data;
          setStatistics(response.data);
        })
      } catch (error: any) {
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchData();
  }, [token, setAuthenticated, enqueueSnackbar]);

  if (loading) {
    // Show loader while data is being fetched
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      {/* Bread Crumb + Create Button */}
      <div className='flex items-center justify-between pb-3 h-12'>
        <CustomBreadcrumbs
          links={[
            { name: 'Overview', href: routes.dashboard },
            { name: 'Dashboard', href: routes.dashboard },
          ]}
        />
      </div>
      
      <div className='flex flex-col pb-5'>
        <div className='flex flex-row h-40 gap-5'>
          <Card className='h-full w-1/4'>
            <StatisticCard
              // icon="ph:user-duotone"
              icon="solar:user-bold"
              value={statistics?.childCounts}
              title="Children"
            />
          </Card>
          <Card className='h-full w-1/4'>
            <StatisticCard
              icon="solar:users-group-rounded-bold"
              value={statistics?.parentCounts}
              title="Parents"
            />
          </Card>
          <Card className='h-full w-1/4'>
            <StatisticCard
              icon="solar:medal-ribbon-bold-duotone"
              value={statistics?.challengeCounts}
              title="Challenges"
            />
          </Card>
          <Card className='h-full w-1/4'>
            <StatisticCard
              icon="solar:medal-ribbon-bold-duotone"
              value={statistics?.userChallengeCounts}
              title="User Challenges"
            />
          </Card>
        </div>
        <div className='flex flex-row h-auto gap-5 mt-5'> 
          <Card className='h-full w-1/3'>
            <ChildrenGender statistics={statistics} />
          </Card>
          <Card className='h-full w-2/3'>
            <TotalChildren statistics={statistics} />
          </Card>
        </div>
        <div className='flex flex-row h-auto gap-5 mt-5'>
          <Card className='h-full w-full'>
            <AgeRanges statistics={statistics}/>  
          </Card>
        </div>
        <div className='flex flex-row h-auto gap-5 mt-5'> 
          <Card className='h-full w-1/2'>
            <TotalChallenges statistics={statistics} />
          </Card>
          <Card className='h-full w-1/2'>
            <TotalUserChallenges statistics={statistics} />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;