import React from 'react';

import CustomBreadcrumbs from '../../../components/minimal/custom-breadcrumbs';

import { Card } from '@mui/material';

import BadgeForm from './components/badge-form';

import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';

// ----------------------------------------------------------------------

interface EditBadgeProps {
}

// ----------------------------------------------------------------------

const EditBadge: React.FC<EditBadgeProps> = () => {

  const navigate = useNavigate();

  const handleCloseForm = () =>{
    navigate(routes.badges);
  }

    return(
        <div>
            {/* Bread Crumb */}
            <div className='flex items-center justify-between pb-3 h-12'>
              <CustomBreadcrumbs
                links={[
                  { name: 'Management', href: routes.badges },
                  { name: 'Badge', href: routes.badges },
                  { name: 'Edit' },
                ]}
              />
            </div>
            {/* Badge Form */}
            <div className='flex flex-row min-h-96'>
                <h2 className='uppercase font-bold w-1/2'>Info</h2>
                <Card className='w-1/2 p-5'>
                  <BadgeForm onClose={handleCloseForm} />
                </Card>
            </div>
        </div>
    )
};

export default EditBadge;